import request from '../utils/requrst'
// 构建授权链接

export function getOfficalAccountOauth(params) {
  return request({
    url: "/api/wxOffical/officalAccountOauth",
    method: "GET",
    params
  });
}
// 食疗课程详情
export function getProductSlDetail(params) {
  return http({
    url: "/api/product/sl_detail",
    method: "GET",
    params
  });
}

// 调用支付
export function getSlUnifyPayH5(data) {
  return http({
    url: "/api/order/sl_unifyPayH5",
    method: "POST",
    data
  });
}
// 查询支付结果
export function getOrder(params) {
  return http({
    url: "/payment/order/info",
    method: "GET",
    params
  });
}
// 收集信息
export function getUserInfo(data) {
  return http({
    url: "/api/order/orderAddUserInfo",
    method: "POST",
    data
  });
}

// 推广h5微信支付
export function getTgUnifyPayH5(data) {
  return http({
    url: "/api/order/tg_unifyPayH5",
    method: "POST",
    data
  });
}

// 推广授权
export function getTgWechatOauth(params) {
  return http({
    url: "/api/tgWechatOauth",
    method: "GET",
    params
  });
}
// 获取推广支付是否支付成功
export function getTgOrderInfo(params) {
  return http({
    url: "/api/order/tg_order_info",
    method: "GET",
    params
  });
}