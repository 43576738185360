<template>
  <div class="bigDIv">
    <van-overlay :show="ISshow" @click="ISshow=false">
      <div class="wrapper" @click.stop>
        <div class="block" />
      </div>
    </van-overlay>
    <div v-if="show" class="animate__animated animate__slideOutUp class" :key="currentIndex">{{ currentItem }}领取了课程</div>
    <!--
   -->
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111059552980343.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111064490486269.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111066723398056.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111068115454328.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111069987067409.jpg" alt="">
    <div class="clickPay" @click="getCode">点击支付</div>
    <!-- <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/16/172111074634101899.gif" alt="" class="fixdBto" @click="getCode"> -->
    <!-- 二维码弹框  -->
    <div class="erweima" v-if="isPay">
      <img :src="imgUrl" alt="" class="ewmImg">
    </div>
  </div>
</template>

<script>
import 'animate.css';
import { getTgWechatOauth, getTgUnifyPayH5, getTgOrderInfo } from "@/api/h5Pay.js";
import { getQrcode, getWxCsad } from "../../api/components";
import { Toast } from 'vant';
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      lastClickTime: 0,
      memberInfo: {},
      ruleForm: {

        land_link: "", //落地页链接
      },
      items: [' 高***灵8秒前', ' 王***西17秒前', ' 李***定23秒前', ' 钱**林39秒前', ' 冼***星一分钟前'], // 数组中的项
      text: ['高***康', '王***西', '李***明', '张***明'],
      currentIndex: 0, // 当前显示的项的索引
      currentItem: '', // 当前显示的项
      show: true, // 控制项的显示和隐藏
      qr_id: '',
      isPay: false,
      ISshow: false,
      imgUrl: ''
    }
  },
  created () {
    this.qr_id = this.$route.query.qr_id
    this.ruleForm.land_link = window.location.href
    const code = this.$route.query.code

    if (code) {

      this.getTgWechatOauth()
    } else {
      // this.getCode()
    }
    // this.getQrcode()

  },
  methods: {
    // 获取是否支付
    async getTgOrderInfo () {
      this.ISshow = true
      const query = {
        qr_id: this.qr_id,
        open_id: this.memberInfo.open_id
      }
      const { data } = await getTgOrderInfo(query)
      console.log(data.data);
      if (data.data.is_pay) {
        this.$toast.clear()
        this.isPay = true
        this.ISshow = true
        Toast.success('您已经报名成功!')
      } else {
        this.getTgUnifyPayH5()
      }
    },
    // 获取二维码
    async getQrcode () {
      const query = {
        qr_id: this.qr_id
      }
      const { data } = await getQrcode(query)
      console.log(data);
      this.imgUrl = data.data.img
    },
    // 获取网页授权
    async getTgWechatOauth () {
      try {
        const query = {
          code: this.$route.query.code
        }
        const { data } = await getTgWechatOauth(query)
        // localStorage.setItem('token', data.data.token)
        this.memberInfo = data.data
        console.log(data.data);
        console.log(this.memberInfo, '88');
        this.$toast.loading('正在加载,请稍等~')
        this.getTgUnifyPayH5()
        if (data.data.open_id) {
          // 
          // this.getTgOrderInfo()
        } else {
          this.getCode()
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTgUnifyPayH5 () {
      try {
        const query = {
          open_id: this.memberInfo.open_id,
          price: this.$route.query.price || 0
        }
        const res = await getTgUnifyPayH5(query)
        console.log(res, '8897879');
        this.wechatPay(res);
      } catch (error) {
        console.log(error, '我是错误');
        // this.getCode()
      }
    },
    async wxClick () {
      const qqq = {
        land_link: 'https://kuaishou.hshwhkj.com/ksFoodAPay?channel_id=6&qr_id=61001&callback=RiDscXOQI'
      }
      const { data } = await getWxCsad(qqq);
      window.location.href = data.data;
    },
    wechatPay (res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no
        } = res.data.data.data;
        console.log(appId, '8888');

        wx.config({
          debug: false,
          appId,
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"]
        });
        this.$toast.clear()

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log('成功');
            // this.isPay = true
            // this.ISshow = true
            this.wxClick()
            // alert('支付成功')

            // 支付成功后的回调函数
            // this.$router.replace({
            //   path: "/payok",
            //   query: {
            //     orderid: trade_no
            //   }
            // });
          }
        });
      } else {
        window.location.href = res.data.data;
      }
    },
    is_weixn () {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode (isCode) {
      console.log(123, "获取code");

      let scope = "snsapi_base"; //静默授权
      let info = {
        appid: "wx2c29290474fbb8b6"
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/beforePay?qr_id=${this.qr_id}&price=${this.$route.query.price}`
        )}&response_type=code&scope=${scope}#wechat_redirect`;
      // }
    },
    startTimer () {
      this.currentItem = this.items[this.currentIndex];
      setInterval(() => {
        this.show = false; // 隐藏当前项，删除盒子
        setTimeout(() => {
          this.currentIndex = (this.currentIndex + 1) % this.items.length; // 更新索引
          this.currentItem = this.items[this.currentIndex]; // 更新当前项
          this.show = true; // 重新显示盒子
        }, 1000); // 1秒后重新创建盒子
      }, 3000); // 每隔两秒切换到下一项
    },


    throttleSubmit () {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.getCode()
    },

  },
  mounted () {
    this.startTimer();
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDIv {
  width: 100%;
  height: 812px;
  position: relative;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  flex-direction: column;
  .clickPay {
    width: 375px;
    height: 50px;
    border-radius: 50px;
    background-color: #28b881;
    color: #fff;
    text-align: center;
    line-height: 50px;
    animation: clickPay 1s infinite;
    position: fixed;
    bottom: 20px;
    left: 0;
  }
  @keyframes clickPay {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  .wx {
    position: fixed;
    top: 50%;
    right: 10px;
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
  img {
    width: 100%;
  }
  .fild {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .class {
    font-size: 24px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 12px;
    width: 150px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50px;
    line-height: 30px;
    text-align: center;
  }
  .btn {
    margin-bottom: 50px;
  }
  .fixdBto {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .animate__animated.animate__slideOutUp {
    --animate-duration: 3s;
  }
}
@media screen and (min-width: 750px) {
  .bigDIv {
    width: 750px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .wx {
      position: fixed;
      top: 50%;
      right: 10px;
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
    img {
      width: 100%;
    }
    .fild {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .wx {
      position: fixed;
      top: 50%;
      right: 25%;
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
    .fild {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
      opacity: 0;
    }
    .class {
      font-size: 24px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 40px;
      font-size: 12px;
      width: 150px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      border-radius: 50px;
      line-height: 30px;
      text-align: center;
    }
    .btn {
      margin-bottom: 150px;
    }
    .fixdBto {
      position: fixed;
      width: 750px;

      bottom: 0;
    }
    .animate__animated.animate__slideOutUp {
      --animate-duration: 3s;
    }
  }
}
</style>